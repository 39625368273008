<template>
  <section class="section full-height" style="margin-top:120px">
    <div class="container full-height">
      <div class="columns full-height is-vcentered is-centered is-mobile">
        <div class="column is-11-mobile is-9-tablet is-7-desktop is-6-widescreen card">
          <section class="section full-height">
            <div class="columns">
              <div class="column">
                <figure class="image" style="margin:auto;width: 260px;">
                  <img src="@/assets/logo.350.png" alt="Logo Image" />
                </figure>
              </div>
            </div>
            <form id="login">
              <div class="columns">
                <b-field class="column" label="Login">
                  <b-input type="text" name="login" v-model="form.login" @keyup.enter.native="submit" />
                </b-field>
              </div>
              <div class="columns">
                <b-field class="column" label="Senha">
                  <b-input type="password" name="senha" v-model="form.senha" @keyup.enter.native="submit" />
                </b-field>
              </div>
              <div class="columns">
                <div class="column has-text-centered">
                  <b-button class="btn btn-primary btn-round" @click="submit">
                    Login
                  </b-button>
                </div>
              </div>
            </form>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import userService from '@/services/userService';
import { mapMutations } from 'vuex';
export default {
  name: 'Login',
  data() {
    return {
      form: {},
    };
  },
  methods: {
    ...mapMutations(['setUser']),
    submit() {
      this.isLoading = true;
      return userService
        .autenticar(this.form)
        .then(({ data }) => {
          this.setUser(data);

          this.$buefy.snackbar.open({
            duration: 3000,
            message: 'Usuário autenticado',
            type: 'is-success',
            position: 'is-bottom-right',
            actionText: 'OK',
            queue: false,
          });

          this.$router.push({ name: 'relatorio-entrega-ingressos' });
        })
        .catch(e => {
          this.$buefy.snackbar.open({
            duration: 3000,
            message: e.message,
            type: 'is-danger',
            position: 'is-bottom-right',
            actionText: 'OK',
            queue: false,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
